

























































































import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      loading: true,
      paginate: {
        keywords: '',
        page: 1,
        size: 10,
        total: 0,
        orderBy: {},
      },
      entities: [],
      selection: [],
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.loading = true
      const res = await this.$http.request({
        url: 'goods',
        method: 'get',
        params: this.paginate,
      })
      this.entities = res.data.data
      this.loading = false
      this.paginate.total = res.data.meta.total
    },
    resetSearch() {
      this.paginate.keywords = ''
      this.paginate.page = 1
      this.fetch()
    },
    handleSizeChange(size: number) {
      this.paginate.size = size
      this.fetch()
    },
    handleCurrentChange(page: number) {
      this.paginate.page = page
      this.fetch()
    },
    handleSortChange({ prop, order }: { prop: any; order: any }) {
      if (order) {
        this.paginate.orderBy = {
          prop,
          order: order.replace('ending', ''),
        }
      } else {
        this.paginate.orderBy = {}
      }
      this.fetch()
    },
    handleSelectionChange(val: any) {
      this.selection = val.map((i: any) => i.id)
    },
    handleRemove(row: any) {
      this.$confirm(`此操作将永久删除"${row.id}", 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        await this.$http.delete(`goods/${row.id}`)
        this.$message.success('删除成功')
        this.fetch()
      })
    },
    async handleExport() {
      const response = await this.$http.post('goods/export', this.paginate, {
        responseType: 'arraybuffer',
      })
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      })
      let downloadElement = document.createElement('a')
      let href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      downloadElement.download = `table.xlsx`
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    },
    async handleSelectionDelete() {
      if (!this.selection.length) {
        return this.$message.error('请先选择要删除的商品')
      }
      this.$confirm('数据删除后不可以再恢复，确认要删除吗?', {
        type: 'warning',
      }).then(async () => {
        await this.$http.delete(`goods/selection/delete`, {
          params: {
            selection: this.selection,
          },
        })
        this.$message.success('删除成功')
        this.fetch()
      })
    },
    async handleSelectionChangeArea() {
      if (!this.selection?.length) {
        return this.$message.error('请先选择商品')
      }
      const result: any = await this.$prompt('请输入地区', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })

      const area = result?.value?.trim()
      if (!area) {
        return this.$message.error('请输入地区')
      }
      try {
        this.loading = true
        await this.$http.post('goods/selection/area', {
          selection: this.selection,
          area,
        })
        this.$message.success('修改成功')
        this.fetch()
      } catch (error) {
        this.loading = false
      }
    },
  },
})
